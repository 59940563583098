import React, { Component } from 'react'
import '../App.css'
import language from './es-mx.json'

class CloudinaryUploadWidget extends Component {
  componentDidMount() {
    const lang = language
    const myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'yuhmak',
        uploadPreset: 'm2hohfxl',
        language: 'es',
        text: lang,
        sources: ['local', 'camera', 'google_drive'],
        showAdvancedOptions: true,
        cropping: false,
        multiple: true,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#FFFFFF',
            windowBorder: '#90A0B3',
            tabIcon: '#00A541',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#00A541',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
          fonts: {
            default: null,
            "'Fira Sans', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Fira+Sans',
              active: true,
            },
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          console.log('Done! Here is the image info: ', result.info)
        }
      },
    )
    document.getElementById('upload_widget').addEventListener(
      'click',
      function () {
        myWidget.open()
      },
      false,
    )
  }

  render() {
    return (
      <div id="upload_widget" className="c_loudinary-button">
        +
      </div>
    )
  }
}

export default CloudinaryUploadWidget
