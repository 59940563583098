import './App.css'
import React, { useEffect, useState } from 'react'
import CloudinaryUploadWidget from './services/CloudinaryUploadWidget'
import PT from 'prop-types'
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from 'react-lightgallery'

//
const PhotoItem = ({ image, thumb, group }) => (
  <div style={{ maxWidth: '250px', width: '200px', padding: '5px' }}>
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: '300px' }} alt="" />
    </LightgalleryItem>
  </div>
)
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
}
//

function App() {
  const [images, setImages] = useState([])
  const [searchBarTerm, setSearchBarTerm] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [allData, setAllData] = useState([])

  const fetchItems = () => {
    fetch('https://res.cloudinary.com/yuhmak/image/list/motos.json')
      .then((response) => response.json())
      .then((json) => setImages(json.resources))
  }

  useEffect(() => {
    if (!searchBarTerm) {
      setSearchResult([])
      return false
    }

    searchBarTerm.length > 2 &&
      images &&
      setSearchResult(
        images.filter(
          (termino) =>
            termino.public_id
              .toLowerCase()
              .indexOf(searchBarTerm.toLocaleLowerCase().replace(' ', '-')) >
              -1 && termino,
        ),
      )
  }, [searchBarTerm])

  useEffect(() => {
    fetchItems()
  }, [])

  useEffect(() => {
    const finalData = searchResult.map((data) => {
      const splited = data.public_id.split('-')
      const sku = splited[0]
      const marmod = splited.reduce((acc, el, index) => {
        return index !== 0 ? acc.concat(` ${el}`) : ''
      }, '')
      const desc = { sku, marmod }
      data.desc = desc
      return {
        sku,
        marmod,
        ...data,
      }
    })

    console.log('finaldata', finalData)

    setAllData(finalData)
  }, [searchResult])

  return (
    <div className="App">
      <LightgalleryProvider
        onBeforeOpen={() => console.info('onBeforeOpen')}
        onAfterOpen={() => console.info('onAfterOpen')}
        onSlideItemLoad={() => console.info('onSlideItemLoad')}
        onBeforeSlide={() => console.info('onBeforeSlide')}
        onAfterSlide={() => console.info('onAfterSlide')}
        onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
        onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
        onDragstart={() => console.info('onDragstart')}
        onDragmove={() => console.info('onDragmove')}
        onDragend={() => console.info('onDragend')}
        onSlideClick={() => console.info('onSlideClick')}
        onBeforeClose={() => console.info('onBeforeClose')}
        onCloseAfter={() => console.info('onCloseAfter')}
      >
        <header className="search-bar">
          <div className="logo">
            <img
              src="https://http2.mlstatic.com/storage/mshops-appearance-api/images/1/570640501/logo-2021072718555503300.png"
              alt=""
            />
          </div>
          <div>
            <form style={{ textAlign: 'center' }}>
              <input
                autoFocus
                type="search"
                onChange={(e) => setSearchBarTerm(e.target.value)}
              />
              <div
                style={{ fontSize: 'small', color: '#fff', padding: '.5em 0' }}
              >
                {allData && allData.length > 0
                  ? allData.length === 1
                    ? `Se encontró 1 resultado para "${searchBarTerm}"`
                    : allData.length > 1
                    ? `Se encontraron ${allData.length} resultados para "${searchBarTerm}"`
                    : 'Se recomienda buscar por código SAP'
                  : `No se encontraron resultados para "${searchBarTerm}"`}
              </div>
            </form>
          </div>
        </header>
        <div className="container">
          {allData.length > 0 &&
            allData.map((image, idx) => (
              <div key={image.public_id} className="picture-box">
                {/* <a
                  href={`https://res.cloudinary.com/yuhmak/image/${image.type}/v${image.version}/${image.public_id}.${image.format}`}
                  target="_blank"
                  rel="noreferrer"
                > */}
                {
                  <PhotoItem
                    key={idx}
                    image={`https://res.cloudinary.com/yuhmak/image/${image.type}/v${image.version}/${image.public_id}.${image.format}`}
                    group="group1"
                  />
                }
                {/* <img
                  src={`https://res.cloudinary.com/yuhmak/image/${image.type}/v${image.version}/${image.public_id}.${image.format}`}
                  width="300px"
                  alt=""
                /> */}
                {/* </a> */}
                <div className="link-desc">
                  <strong style={{ color: '#00a541' }}>{image.sku}</strong>
                  <br></br>
                  <strong>{image.marmod}</strong>
                  <br></br>
                  {`https://res.cloudinary.com/yuhmak/image/${image.type}/v${image.version}/${image.public_id}.${image.format}`}
                </div>
              </div>
            ))}
        </div>
      </LightgalleryProvider>
      <CloudinaryUploadWidget />
    </div>
  )
}

export default App
